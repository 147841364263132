<template>
	<div>
		<div class="ser-form">
			<Form :model="formData" :label-width="0" inline>
				<FormItem>
					<Input placeholder="请输入商品名称或订单编号" style="width:250px;" v-model="formData.orderid"></Input>
					<Button icon="md-search" style="margin-left: 10px;" type="primary" @click="init()">搜索</Button>
					<Button type="error" style="margin-left: 10px;" @click="del_all" icon="md-trash">删除所有未支付订单</Button>
				</FormItem>
			</Form>
		</div>
		<div class="table-list">
			<Row type="flex" style="margin-bottom: 12px;">
				<Col class="table-th" span="8">订单信息</Col>
				<Col class="table-th th-center" span="4">订单价格</Col>
				<Col class="table-th th-center" span="4">支付方式</Col>
				<Col class="table-th th-center" span="3">订单状态</Col>
				<Col class="table-th th-center" span="5">操作</Col>
			</Row>
			<div class="table-tab">
				<div class="table-item" v-for="(item,index) in data" :key="index">
					<div class="table-orderid">
						<span class="order-time">下单时间：{{item.create_time}}</span>
						<span class="order-code">订单编号：{{item.orderid}}</span>
					</div>
					<Row class-name="table-cont">
						<Col class-name="table-tr" span="8">
						<img class="order-img" :src="ImgUrl+item.thumb" alt="">
						<div class="order-name">
							<div class="goods_name">{{item.title}}</div>
							<div class="f-right order-price">￥<span class="order-price-qty">{{item.price}}</span></div>
							<div class="f-right order-qty">x{{item.qty}}</div>
						</div>
						</Col>
						<Col class-name="table-tr table-td-price" span="4">
						<div class="td-price-money">订单金额：<span class="money">{{item.money}}元</span></div>
						</Col>
						<Col class-name="table-tr table-td-price" span="4">
						<div>
							<Tag>{{ pay_type[item.payment] }}</Tag>
						</div>
						<div>{{ item.pay_time }}</div>
						</Col>
						<Col class-name="table-tr table-td-price" span="3">
						<Tag :color="item.status?'success':'error'">{{ pay_status[item.status] }}</Tag>
						</Col>
						<Col class-name="table-tr table-td-action" span="5">
						<Button class="td-btn" type="error" size="small" @click="del_order(item.id)">删除订单</Button>
						</Col>
					</Row>
				</div>
				<div v-if="data.length == 0" class="table-null">
					没有更多订单了
				</div>
			</div>
		</div>
		<Spin size="large" v-if="spin_show" fix></Spin>
		<Page class-name="page-r f-right" :total="total" :page-size="formData.page_size" :page-size-opts='[10,20,30]' show-total show-sizer @on-change="set_page"
			@on-page-size-change="set_limit" :current="formData.page"></Page>
	</div>
</template>

<script>
	export default {
		name: "AiOrder",
		data() {
			return {
				formData: {
					orderid: '',
					page: 1,
					page_size: 10
				},
				spin_show: false,
				ImgUrl: this.ImgUrl,
				pay_type: ['', '支付宝支付', '微信支付'],
				pay_status: ['未支付','已支付','已取消'],
				total: 0,
				data: []
			}
		},
		methods: {
			init() {
				var _this = this
				this.spin_show = true
				this.requestApi('/adm/get_ai_order', {
					RuleForm: this.formData
				}).then(
					(res) => {
						_this.spin_show = false
						_this.formData.page = res.data.current_page
						_this.formData.page_size = res.data.per_page
						_this.total = res.data.total
						_this.data = res.data.data
					}
				)
			},
			set_page(e) {
				this.formData.page = e
				this.init()
			},
			set_limit(e) {
				this.formData.page_size = e
				this.init()
			},
			del_order(id) {
				var _this = this
				this.$Modal.confirm({
					title: '删除订单',
					content: '确定删除该订单吗？',
					onOk: () => {
						_this.requestApi('/adm/del_ai_order',{id}).then(
							(res) => {
								if(res.code == 200) {
									_this.$Message.success(res.msg)
								}else{
									_this.$Message.error(res.msg)
								}
								_this.init()
							}
						)
					}
				})
			},
			del_all() {
				let _this = this
				this.$Modal.confirm({
					title: '删除订单',
					content: '确认删除所有未支付订单吗?',
					okText: '删除',
					onOk: () => {
						this.requestApi('/adm/del_all_order',{}).then(
							(res) => {
								if(res.code == 200) {
									_this.$Message.success(res.msg)
								}else {
									_this.$Message.error(res.msg)
								}
								_this.init()
							}
						)
					}
				})
			}
		}
	}
</script>

<style scoped>
	.table-th {
		background: #f8f8f8;
		padding: 8px 15px;
		font-size: 13px;
		font-weight: 600;
	}

	.th-center {
		text-align: center;
	}

	.table-orderid {
		padding: 0 15px;
		height: 40px;
		line-height: 40px;
		background: #f8f8f8;
		font-size: 13px;
	}

	.table-cont {
		font-size: 13px;
		background-color: #FFFFFF;
	}

	.table-cont .table-tr {
		display: flex;
		padding: 8px 12px;
	}

	.table-td-price {
		flex-flow: column;
		align-items: center;
		justify-content: center;
	}

	.table-td-price .td-price-money {
		color: #999999;
		height: 24px;
		line-height: 24px;
	}

	.table-td-price .td-price-money span.money {
		color: #f5222d;
	}

	.table-td-action {
		flex-flow: row;
		align-items: center;
		justify-content: center;
	}

	.table-item {
		border: 1px solid #efefef;
		margin-bottom: 12px;
	}

	.order-time {
		color: #999999;
		margin: 0 10px;
	}

	.order-code {
		color: #f5222d;
		margin: 0 10px;
	}

	.order-dtl {
		display: inline-block;
		padding: 0 15px;
		float: right;
	}

	.order-img {
		flex-shrink: 0;
		width: 170px;
		height: 80px;
		margin-right: 12px;
	}

	.order-name {
		flex: 2;
		width: 100%;
		flex-shrink: 0;
		overflow: hidden;
	}

	.order-name .goods_name {
		white-space: nowrap;
		text-overflow: ellipsis;
		overflow: hidden;
		margin-top: 8px;
		color: #111111;
	}

	.order-price {
		color: #f5222d;
	}

	.order-price-qty {
		font-size: 18px;
	}

	.order-qty {
		color: #999999;
	}

	.f-right {
		text-align: right;
	}
	
	.table-null {
		text-align: center;
		padding: 8px 0px;
	}
	
	.td-btn {
		font-size: 12px;
	}
</style>
