<template>
  <div>
    <div class="table-list">
      <Table :columns="columns" :data="data" :loading="table_loading"></Table>
    </div>
  </div>
</template>

<script>
    export default {
        name: "",
        data(){
            return{
				table_loading: false,
                columns:[
                    {title:'名称',key:'goods_name'},
                    {title:'剩余可调用量（次）',key:'amount'},
					{title: '上月调用量（次）',key: 'amount3'},
                    {title:'历史调用量（次）',key:'amount2'},
                    {title:'到期时间',key:'out_time'},
                ],
                data:[
                    {goods_name:'舌诊API', amount:10, amount2:0,amount3: 10, out_time:'2021-7-8 19:47:59'},
                    {goods_name:'面诊API', amount:100, amount2:0,amount3: 10, out_time:'2021-7-8 19:47:59'},
                    {goods_name:'手诊API', amount:200, amount2:0,amount3: 10, out_time:'2021-7-8 19:47:59'},
                    {goods_name:'穴位识别API', amount:90, amount2:0,amount3: 10, out_time:'2021-7-8 19:47:59'},
                    {goods_name:'舌诊医学影像API', amount:80, amount2:0,amount3: 10, out_time:'2021-7-8 19:47:59'},
                    {goods_name:'智能体质识别', amount:140, amount2:0,amount3: 10, out_time:'2021-7-8 19:47:59'},
                    {goods_name:'中医智能辨证系统', amount:1000, amount2:0,amount3: 10, out_time:'2021-7-8 19:47:59'},
                ],
            }
        },
        methods:{
            init(){
				var _this = this
				this.table_loading = true
				this.requestApi('/adm/get_ai_history',{}).then(
					(res) => {
						_this.table_loading = false
						_this.data = res.data
					}
				)
				
            }
        }
    }
</script>

<style scoped>
  .table-list{
    border: 1px solid #e8eaec;
    border-bottom: 0;
  }
</style>
