<template>
  <div>
    <div class="api-box">
      <div class="api-option">
        <div class="api-item" v-for="(item,index) in data" :key="index">
          <img class="api-item-img" :src="ImgUrl+item.thumb" alt="" />
          <div class="api-item-title">{{item.title}}</div>
          <div class="api-item-describe">
            {{item.describe}}
          </div>
          <div class="api-item-btn" @click="openPay(item)">去开通</div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
    export default {
        name: "AiList",
        data() {
            return {
                msg: 'hello world!',
                data:[],
                apiUrl:this.ApiUrl,
				ImgUrl: this.ImgUrl,
            }
        },
        methods: {
            //获取api列表
            init() {
                var _this = this;
                this.requestApi('/adm/get_ailist').then(function (res) {
                    if (res.status){
                        _this.data = res.data;
                    }
                })
            },
            //立即开通
            openPay(item) {
                this.toUrl('/aidtl',{id:parseInt(item.id),type:parseInt(item.type)})
            }
        }
    }
</script>

<style scoped>
  .api-box {
    margin-left: -12px;
    background-color: #FFFFFF;
  }

  .api-option {
    display: flex;
    flex-wrap: wrap;
  }

  .api-item {
    margin-left: 16px;
    margin-bottom: 12px;
    width: 190px;
    height: auto;
    box-shadow: 0 0 5px 0px #999999;
  }

  .api-item-img {
    display: block;
    width: 190px;
    height: 100px;
  }

  .api-item-title, .api-item-describe {
    width: 190px;
  }

  .api-item-title {
    padding: 8px;
    text-align: center;
    font-size: 16px;
    font-weight: 500;
    text-overflow: ellipsis;
    white-space: nowrap;
    overflow: hidden;
  }

  .api-item-describe {
    padding: 0 8px;
    height: 70px;
    line-height: 1.5;
    font-size: 12px;
    overflow: hidden;
  }

  .api-item-btn {
    margin: 10px auto;
    background: #ff8a00;
    width: 110px;
    min-width: 110px;
    padding: 0 10px;
    height: 40px;
    line-height: 40px;
    text-align: center;
    font-size: 16px;
    color: #fff;
    display: block;
    cursor: pointer;
  }
</style>
