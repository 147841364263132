<template>
  <div class="api-main">
    <Tabs v-model="TabsIndex" :animated="false" @on-click="changeTabs">
      <TabPane label="应用服务" name="ailit">
        <ai-list ref="ailit"></ai-list>
      </TabPane>
      <TabPane label="我的应用" name="myai">
        <my-ai ref="myai"></my-ai>
      </TabPane>
      <TabPane label="我的订单" name="myorder">
        <my-order ref="myorder"></my-order>
      </TabPane>
    </Tabs>
  </div>
</template>

<script>
  import AiList from '@/views/ai/ailist'
  import MyAi from '@/views/ai/myai'
  import MyOrder from '@/views/ai/order'
    export default {
        name: "AiPayment",
        components:{
            AiList,MyAi,MyOrder
        },
        data(){
            return{
                TabsIndex:'ailit',
                msg:'aa'
            }
        },
        mounted() {
          this.init();
        },
        watch:{
            TabsIndex(name){
                this.$refs[name].init();
            }
        },
        methods:{
            //根据
            init(){
                this.$refs[this.TabsIndex].init();
            },
            changeTabs(name){
                this.TabsIndex = name;
            }
        }
    }
</script>

<style scoped>
.api-main{
  padding: 15px;
  background-color: #FFFFFF;
}

</style>
